/**======================================================================
=========================================================================
Template Name: Gradient able Bootstrap Admin Template
Author: Phoenixcoded
Support: support@phoenixcoded.net
File: style.css
=========================================================================
=================================================================================== */


// theme font
@import "themes/font/theme-font";

// theme scrollbar
@import "themes/plugins/perfect-scrollbar";

// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";
@import "node_modules/bootstrap/scss/bootstrap";

// icon
@import "themes/font/datta-icon";
@import "themes/font/feather";
@import "themes/font/fontawesome";
@import "themes/font/flaticon";

// bootstrap css
@import "themes/bootstrap-overlay/card";
@import "themes/bootstrap-overlay/button";
@import "themes/bootstrap-overlay/radiobox-checkbox";
@import "themes/bootstrap-overlay/switches";
@import "themes/bootstrap-overlay/badge";

// utility css & animate css
@import "themes/general";
@import "themes/generic";
@import "plugins/animate.min.css";

// theme layouts
@import "themes/layouts/menu-lite";
@import "themes/chat/chat";

// dashboard widgets
@import "themes/dashboard/widget";

// custom pluginss
@import "themes/plugins/sweatalert";
@import "themes/plugins/lightbox";
@import "themes/plugins/notification";
@import "themes/plugins/pnotify";
@import "themes/plugins/bar-rating";
@import "themes/plugins/rangeslider";
@import "themes/plugins/daterange";
@import "themes/plugins/tags-input";
@import "themes/plugins/maxlength";
@import "themes/plugins/wizard";
@import "themes/plugins/select2";
@import "themes/plugins/data-tables";

// charts
@import "themes/plugins/highcharts";
@import "themes/plugins/peitychart";

// pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
